<template>
	<transition name="slide-fade">

		<!-- Template Preview Card -->
		<b-card v-if="$checkPermission('view-template')" class="mb-3 template-card" :style="{ 'min-width': cardWidth, 'max-width': cardWidth }" :class="{ 'card-margin-left': hasMargin }">

			<!-- Template Preview Card Header -->
			<template v-slot:header>
				<b-row>
					<b-col class="ellipsable-text">
						<b-card-text class="template-card-title">
							<font-awesome-icon :icon="getTemplateTypeIcon(template.type)" class="mr-2" />{{ template.name }}
						</b-card-text>
					</b-col>
					<b-col sm="3" class="pr-0">
						<b-form-checkbox ref="status" class="btn-status" :name="template.name" switch size="lg"
							v-if="tabIndex==0 && $checkPermission('enable-campaign-template')" style="z-index:9" button-variant="info"
							@input="changeTemplateStatus()" value="1" unchecked-value="0" v-model="template.status">
							<span class="sr-only">{{ template.status== 1 ? $t('Template.Enabled', { name: template.name }) :
									$t('Template.Disabled', { name: template.name }) }}</span>
						</b-form-checkbox>
						<div class="btn-delete-template">
							<b-form-checkbox ref="delete" class="btn-status" :name="template.name" switch size="lg"
								v-if="canDelete && $checkPermission('delete-template')" style="z-index:9" button-variant="info"
								@input="changeDeletedStatus()" value="null" unchecked-value="1" v-model="template.deleted">
								<span class="sr-only">{{ template.deleted== 1 ? $t('Template.Deleted', { name: template.name }) :
									$t('Template.Existing', { name: template.name }) }}</span>
							</b-form-checkbox>
						</div>
					</b-col>
				</b-row>
			</template>

			<!-- Template Preview Card Body -->
			<div :id="'template-card-'+templateId" class="preview-sm-container">
				<div v-if="!canDelete" :class="{ 'hidden': template.status == '1' }" class="overlay"></div>
				<div v-else :class="{ 'hidden': template.deleted != '1' }" class="overlay"></div>
				<b-spinner class="spinner" variant="primary" :label="$t('General.Loading')" v-if="loading"></b-spinner>
				<div class="preview-sm">
					<iframe v-if="previewSm" :srcdoc="previewSm" frameborder="0" scrolling="no"></iframe>
				</div>
			</div>
			<div v-if="globalScope" class="global-scope-icon"><font-awesome-icon :icon="['fas', 'globe']" /></div>
			<div class="iframe-overlay" @click="showPreviewModal()"></div>
			
		</b-card>
	</transition>
</template>

<script>
import campaignService from "../services/campaign.service";
import templateService from "../services/template.service";
import { TemplateType, TemplateScope } from "../../../server/common/constants";

export default {
    name: "cg-template-card",
    data: function() {
		return {
			loading: true,
			locale: this.$i18n.locale,
			previewLanguage: '',
			templateContent: null,
			previewSm: null,
			TEMPLATE_TYPE_SMS: null,
			TEMPLATE_TYPE_EMAIL: null,
			TEMPLATE_TYPE_TRAINING: null,
			TEMPLATE_TYPE_QRCODE: null,
			TEMPLATE_TYPE_USB: null
		};
    },
    props: ["template", "campaign-id", "tab-index", "can-delete", "card-per-row", "index", "company-data", "disable-change-status"],
    computed: {
		templateId() {
			return this.template.template_id;
		},
		cardWidth() {
			return (100 - (this.cardPerRow - 1)) / this.cardPerRow + "%";
		},
		hasMargin() {
			return this.index != 0 && (this.index % this.cardPerRow) != 0;
		},
		globalScope() {
			return this.template && this.template.scope == TemplateScope.Global;
		}
    },
    methods: {
		// Change Template status
		async changeTemplateStatus() {
			if(this.disableChangeStatus) {
				// In Remediation Light, Templates are not saved on campaign-templates yet, so this API call is perfectly useless
				return;
			}
			return new Promise(async(resolve, reject) => {
				try {
					await campaignService.changeTemplateStatus(this.campaignId, this.templateId,this.template.status);
					resolve();
				} catch (error) {
					reject(error);
				}
			});
		},        
		// Change Template delete status
		async changeDeletedStatus() {
			return new Promise(async(resolve, reject) => {
				try {
					await templateService.changeDeletedStatus(this.templateId,this.template.deleted);
					resolve();
				} catch (error) {
					reject(error);
				}
			})
		},
		async getTemplateContent() {
			this.templateContent = this.template.body;

			this.previewLanguage = this.templateContent.find(element => element.language == this.locale) ? this.locale : null;
			if (this.previewLanguage == null) {
				// If the 'locale' version does not exist, try displaying the Template in English, otherwise pick the first available language
				this.previewLanguage = this.templateContent.find(element => element.language == "en") ? "en" : this.templateContent[0].language;
			}

			this.previewSm = await this.compilePreview(this.templateContent, this.previewLanguage);

			this.loading = false;
		},
		getTemplateTypeIcon(templateType) {
			switch(templateType) {
				case this.TEMPLATE_TYPE_SMS:
					return ['fas', 'sms'];
				case this.TEMPLATE_TYPE_TRAINING:
					return ['fas', 'chalkboard-teacher'];
				case this.TEMPLATE_TYPE_QRCODE:
					return ['fas', 'qrcode'];
				case this.TEMPLATE_TYPE_USB:
					return ['fab', 'usb'];
				case this.TEMPLATE_TYPE_EMAIL:
				default:
					return ['fas', 'envelope'];
			}
		},
		compilePlaceholders(text, template) {
			let landingPage = this.template.landing_page_url || '#';
			let baseURL = this.companyData.gp_landing_url;
			let logo = '/api/company/' + this.companyData.company.company_id + '/logo?' + Date.now();

			// Extract baseURL from landing_page_url
			if(this.template.landing_page_url) {
				let firstIndex = (this.template.landing_page_url.indexOf('://') > -1 ) ? this.template.landing_page_url.indexOf('://') + 3 : this.template.landing_page_url.length;
				baseURL = this.template.landing_page_url.substr(0, firstIndex);
				let secondUrlPart = this.template.landing_page_url.substr(firstIndex);
				let lastIndex = (secondUrlPart.indexOf('/') > -1) ? secondUrlPart.indexOf('/') : secondUrlPart.length;
				baseURL += secondUrlPart.substr(0, lastIndex);
			}

			let senderRegex = new RegExp(/[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/);
			let senderAddress = senderRegex.exec(template.sender);

			let finalText = text.replace(/{{.LastName}}/gm, `{{.FirstName}}`)
				.replace(/{{.FirstName}} {{.FirstName}}/gm, `{{.FirstName}}`) 
				.replace(/{{.FirstName}}/gm, this.$account.displayname)
				.replace(/{{.Email}}/gm, this.$account.email)
				.replace(/{{.Tracker}}/gm, '')
				.replace(/{{.TrackingURL}}/gm, '')
				.replace(/{{.From}}/gm, template.sender)
				.replace(/{{.SenderEmail}}/gm, senderAddress?.[0])
				.replace(/{{.Position}}/gm, '')
				.replace(/{{.RId}}/gm, '')
				.replace(/{{\.Img=(.+?)\.(jpg|jpeg|png)}}/gi, (match, name, ext) => {
					return `${this.companyData.img_url}/${name}.${ext}`;
				})
				.replace(/{{.BaseURL}}/gm, baseURL)
				.replace(/{{.CompanyLogoAsDataURL}}/gm, logo)
				.replace(/<sms>/gm, '')
				.replace(/<cgtelephone>.*$/gm, '')
				.replace(/{{.URL}}/gm, this.template.type == this.TEMPLATE_TYPE_EMAIL ? landingPage : '<a href="' + landingPage + '">' + landingPage + '</a>')
				.replace(/{{\.*[a-zA-Z0-9\s][^}]*}}/gm, '<invalid-placeholder>');

			return finalText;
		},
		compilePreview(templateContent, language) {
			return new Promise((resolve, reject) => {
				try {
					let template = templateContent.filter((t) => { return t.language == language; });
					if(template.length == 0) {
						template = templateContent.filter((t) => { return t.language == this.$account.language; });
						if(template.length == 0 && this.companyData && this.companyData.company) {
							template = templateContent.filter((t) => { return t.language == this.companyData.company.default_language; });
						}
						if(template.length == 0) {
							template = templateContent.filter((t) => { return t.language == 'en'; });
							if(template.length == 0) {
								template = templateContent;
							}
						}
					}
					
					template = template[0];

					if(typeof template == 'undefined' || typeof template.body == 'undefined') {
						return resolve('<i class="far fa-edit"></i>');
					}

					let finalBody = this.compilePlaceholders(template.body, template);
					resolve(finalBody);
				} catch (error) {
					reject(error);
				}
			});
		},
		showPreviewModal() {
			this.$emit('preview', this.template, this.index);
		}
	},
	created() {
		this.TEMPLATE_TYPE_SMS = TemplateType.SMS;
		this.TEMPLATE_TYPE_EMAIL = TemplateType.Email;
		this.TEMPLATE_TYPE_QRCODE = TemplateType.QRCode;
		this.TEMPLATE_TYPE_USB = TemplateType.USB;
		this.TEMPLATE_TYPE_TRAINING = TemplateType.Training;

		// Retrieve locale settings
		this.locale = this.$i18n.locale;

		this.getTemplateContent();
	}
};
</script>

<style lang="less">
@preview-height:450px;

a {
    color: #0071EB;
}
.preview-sm {
    cursor: pointer;
    height: 20rem;
    overflow: hidden;
    font-family: sans-serif !important;
}
.template-card {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem !important;
    transition: all 0.15s linear;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4);
    .card-body {
        padding: 1rem;
    }
    .template-card-title {
        font-weight: bold;
        font-size: 18px;
        white-space: nowrap; 
		overflow: hidden; 
		text-overflow: ellipsis;
    }
}
.ellipsable-text {
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}
.template-card:hover {
    box-shadow: -1px 10px 19px 0px rgba(0, 0, 0, 0.6);
}
.placeholder-card {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: @cg-secondary-color;
}
.placeholder-card:after {
    content: '*';
    color: @cg-secondary-color;
}
.placeholder-card-error {
    cursor: pointer;
    text-decoration: line-through;
    text-decoration-color: @cg-error;
}
.placeholder-card-error:after {
    content: '*';
    color: @cg-error;
}
.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.1; 
    transition: all 0.4s linear;
    background: @cg-primary-color;
}
.overlay.hidden {
    opacity: 0;
}
.no-result {
    text-align: center;
    top: 40%;
    position: relative;
}
.btn-status {
    float: right;
    cursor: pointer;
}
.preview-sm-container {
    cursor: pointer;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.card-margin-left {
    margin-left:1% !important;
}
.custom-control-input:checked {
    background-color: #0071EB;
    border-collapse: #0071EB;
}
.custom-switch .custom-control-label {
    cursor: pointer;
}
.custom-switch .custom-control-label::before {
    border: 1px solid @cg-secondary-light-text-color;
}
.custom-switch .custom-control-label::after {
    background-color: @cg-secondary-light-text-color;
}
.btn-delete-template {
    .custom-control-label::before {
        border: 1px solid @cg-dark-gray-text-color;
    }
    .custom-switch .custom-control-label::after {
        background-color: @cg-dark-gray-text-color;
    } 
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    transform: translateY(-91px);
}
.preview-sm iframe {
    height: 800px;
    margin-left: -20%;
    margin-top: -80px;
    width: 200%;
    -webkit-transform: scale(0.8);
    -moz-transform:scale(0.8);
    -o-transform:scale(0.8);
    -ms-transform:scale(0.8);
}
.global-scope-icon {
    position: absolute;
    right: 5%;
    background-color: white;
    padding: 1px 5px;
    border-radius: 50%;
    bottom: 8px;
}
</style>